$(document).ready(function() {
  let type = $('.select_dimension').data('type');

  let division = $('.select_dimension').data('division');
  $(`.select_dimension option[value="${type}"]`).attr("selected", "selected");

  let sort = $('.select_dimension').val();

  $('.division-block').each(function(i, obj) {
    let divisionName = $(this).data('type');

    if(divisionName == division) {
      $(this).removeClass('non-active');
    }

    if (division == 'edit') {
      if (type == 'Region') {
        $('#dimension-name').text('City');
      } else if (type == 'Isp') {
        $('#dimension-name').text('Provider');
      } else {
        $('#dimension-name').text('Product code');
      }
    }
  });

  if(division == 'unmapped') {
    $('#to_field').text(`To: ${$('#object_for_mapping_name').val()}`);
  }

  if(division == 'unmapped') {
    $('#object_for_mapping_name').change(function () {
      $('#to_field').text(`To: ${$(this).val()}`);
    });
  }

  $('.left-arrow').click(function() {
    window.location.href = `${$(this).data('url')}&type=${sort}&division=${division}`;
  });

  $('.right-arrow').click(function() {
    window.location.href = `${$(this).data('url')}&type=${sort}&division=${division}`;
  });

  $('.select_dimension').change(function () {
    window.location.href = `/dimension_mappings?type=${$(this).val()}&division=${division}`;
  });

  $('.division-block').click(function () {
    let divisionName = $(this).data('type');
    window.location.href = `/dimension_mappings?type=${type}&division=${divisionName}`;
  });

  $('.edit_mapping').change(function() {
    if ($(this).data('class') == 'mapping_product_code') {
      $('.update-block').css('display', 'none');
    } else {
      if(this.checked) {
        $('.update-block').css('display', 'block');
        $('.edit_mapping').prop('checked', false);
        $(this).prop('checked', true);

        let className = $(this).data('class');
        let id = $(this).data('id');

        $('form').removeClass();
        $('form').addClass(`edit_${className}`);
        $('form').attr('id', `edit_${className}_${id}`);
        $('#object-name').text($(this).data('name'));
        $(`#${className}_class_name`).val(className);
        $(`#${className}_id`).val(id);
      } else {
        $('.update-block').css('display', 'none');
      }
    }
  });

  $("#search").keyup(function () {
    var value = this.value.toLowerCase().trim();

    $(".table-block table tr").each(function (index) {
      if (!index) return;
      $(this).find("td").each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(value) == -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
  });
});
