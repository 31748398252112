$(document).on('turbolinks:load', function () {
  $('.btn-close').click(function () {
    $('#ModalDate').modal('hide')
  });

  $('.select_picker').selectpicker();

  $(function dateFunction() {
    const txDateInput = document.getElementById('start_and_end_date');
    if (txDateInput) {
      flatpickr(txDateInput, {
        dateFormat: "Y-m-d",
        mode: "range",
        maxDate: new Date().fp_incr(-1),
        onChange: function (selectedDates, dateStr, instance) {
          if (selectedDates.length > 1) {
            var range = instance.formatDate(selectedDates[1], 'U') - instance.formatDate(selectedDates[0], 'U');
            range = range / 86400;
            if (range > 30) {
              $('#ModalDate').modal('show');
              instance.clear()
            }
          }
        },
      });
    }
  })
});
