$(document).on('turbolinks:load', function(){
  $('.sign-off-required').bind('change', function () {
    var value     = this.checked;
    var userId    = $(this).data('user-id');
    var url       = '/billing_sign_offs';
    var parentRow = this.closest('tr');

    var billing_sign_off_data = { sign_off_required: value, user_id: userId }

    if (value == true) {
      $('#days-to-sign-off-for-user-' + userId).removeClass('hide');
    } else {
      $('#days-to-sign-off-for-user-' + userId).addClass('hide');

      billing_sign_off_data['signed_off']       = false
      billing_sign_off_data['days_to_sign_off'] = ''
    }

    $.ajax({
      type: 'POST',
      url: url,
      beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      data: {
        billing_sign_off: billing_sign_off_data,
        use_callbacks:    true
      },
      success: function (data) {
        billingId = JSON.parse(data)['id'];

        parentRow.setAttribute('data-billing-sign-off-id', billingId);
      },
      error: function (request, error) {
        alert("Can't update user because: " + error);
      }
    });
  });

  var select = $('.days-to-sign-off').selectpicker();
  $(select).bind('change', function () {
    var value     = $(this).find('option:selected').val();
    var userId    = $(this).data('user-id');
    var billingId = $(this).closest('tr').data('billing-sign-off-id');
    var url       = '/billing_sign_offs/' + billingId;

    $.ajax({
      type: 'PUT',
      url: url,
      beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      data: {
        billing_sign_off: {
          days_to_sign_off: value,
          user_id:          userId,
          use_callbacks:    true
        }
      },
      error: function (request, error) {
        alert("Can't update user because: " + error);
      }
    });
  });
});
