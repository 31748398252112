$(document).ready(function() {

  let errorsBlock = $('#errors');
  if (errorsBlock.length > 0) {
    let errors = errorsBlock.data(errors);
    if (errors['errors'] != undefined) {
      alert(errors['errors']);
    }
  }

  $('.go_file_generation').click(function (event) {
    event.preventDefault();

    var dataArray = $('#new_file_generation').serializeArray();
    let dataObj = {};
    $(dataArray).each(function(i, field){
      dataObj[field.name] = field.value;
    });

    $('#file_generation_action_name').val($(this).val());

    $('#new_file_generation').attr('action', '/file_generations/generate_file');
    $('#new_file_generation').submit();
  });

  $('.push_to_docuvision').click(function (event) {
    event.preventDefault();

    $('#file_generation_action_name').val($(this).val());

    $('#new_file_generation').attr('action', '/file_generations/generate_file');
    $('#new_file_generation').submit();
  });

  $('#file_generation_year').on('change', function() {
    if(this.value != '') {
      if($('#file_generation_month').val() != '') {
        $('#go_generation').attr('disabled', false);
        $('#generate_isp').attr('disabled', false);
      } else {
        $('#go_generation').attr('disabled', true);
        $('#generate_isp').attr('disabled', true);
      }
    } else {
      $('#go_generation').attr('disabled', true);
      $('#generate_isp').attr('disabled', true);
    }
  });

  $('#file_generation_month').on('change', function() {
    if(this.value != '') {
      if($('#file_generation_year').val() != '') {
        $('#go_generation').attr('disabled', false);
        $('#generate_isp').attr('disabled', false);
      } else {
        $('#go_generation').attr('disabled', true);
        $('#generate_isp').attr('disabled', true);
      }
    } else {
      $('#go_generation').attr('disabled', true);
      $('#generate_isp').attr('disabled', true);
    }
  });
});
