// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('./vendor/popper.js')

import '../stylesheets/application'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap-select/js/bootstrap-select.js'

require('./order_syncs.js')
require('./file_generations.js')
require('./dimension_mappings.js')
require('./multi_select.js')
require('./datepicker.js')
require('./billing_sign_off.js')
require('./setting.js')


import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"
flatpickr(".datepicker", {})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// import images
// require.context('../../assets/images', true)
// require.context('../images', true)
