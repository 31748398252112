$(document).ready(function() {
  $("#sync_time").click(function() {
    $('#time-block').show();
    $('#frequency-block').hide();
  });
  
  $("#sync_frequency").click(function() {
    $('#time-block').hide();
    $('#frequency-block').show();
  });

  $("#sync_time_vx").click(function() {
    $('#time-block-vx').show();
    $('#frequency-block-vx').hide();
  });
  
  $("#sync_frequency_vx").click(function() {
    $('#time-block-vx').hide();
    $('#frequency-block-vx').show();
  });

  $('.sync_fails').click(function(){
    let id = $(this).attr('id').split('-')[1];
    $(`#fail_message_vx-${id}`).show();
    $(`#report_message_vx-${id}`).hide();
  });

  $('.sync_fails').click(function(){
    let id = $(this).attr('id').split('-')[1];
    $(`#fail_message_vx-${id}`).show();
    $(`#report_message_vx-${id}`).hide();
  });

  $('.sync_reports').click(function(){
    let id = $(this).attr('id').split('-')[1];
    $(`#fail_message_vx-${id}`).hide();
    $(`#report_message_vx-${id}`).show();
  });
});

